import React, { useEffect, useState } from "react"

// Libraries
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import CountriesDropdown from "components/countries-dropdown/"

// Icons
import IconLogo from "assets/icons/logo-genesia.inline.svg"
import { colors } from "utils/variables"

const StyledMenu = styled(motion.nav)`
  width: 100%;
  height: 48px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  background-color: ${colors.blanco};
  box-shadow: 0px 0.793533px 7.93533px rgba(109, 141, 173, 0.25);
  z-index: 9999;
  transition: all 0.6s ease;

  ${(props) =>
    props.isScrollingDown
      ? css`
          transform: translateY(-100%);
        `
      : css`
          transform: translateY(0);
        `}

  ${(props) =>
    props.active &&
    css`
      transform: translateY(0);
    `}

  ${breakpoint.medium`
    height: 80px;
    padding: 24px 0;
  `}

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu__countries {
    &::before {
      content: "";
      width: 2px;
      height: 17px;
      display: block;
      margin: 0 8px;
      background-color: ${colors.azul};

      ${breakpoint.small`
        margin: 0 18px;
      `}
    }
  }

  .menu__toggler {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${breakpoint.medium`
      width: 80px;
      height: 80px;
    `}

    span {
      width: 22px;
      height: 2px;
      position: relative;
      margin-bottom: 6px;
      transition: all 0.3s ease;

      &:last-child {
        margin: 0;
      }

      ${(props) =>
        props.active &&
        css`
          &:first-child {
            top: 8px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            top: -8px;
            transform: rotate(-45deg);
          }
        `}
    }
  }

  .menu__content {
    max-height: ${(props) => (props.active ? "1000px" : "0")};
    height: ${(props) => (props.active ? "calc(100vh - 100%)" : "0")};
    // height: calc(100vh - 100%);
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: max-height 0.3s ease;
    overflow: hidden;
    overflow-y: auto;

    ${breakpoint.large`
      max-height: auto;
      height: auto;
      position: relative;
      top: 0;
      padding: 0;
      overflow: visible;
    `}

    ul {
      margin: 120px 0 40px 0;

      ${breakpoint.large`
        margin: 0;
      `}
    }

    li {
      margin-bottom: 65px;

      ${breakpoint.large`
        margin-right: 54px;
        margin-bottom: 0;
      `}

      &:last-child {
        margin: 0;
      }
    }

    a {
      white-space: nowrap;
    }
  }
`

const Menu = () => {
  const [active, setActive] = useState(false)
  const [isScrollingDown, setIsScrollingDown] = useState(false)
  const [lastScrollPosition, setLastScrollPosition] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY

      if (scrollPosition > 0) {
        if (scrollPosition >= lastScrollPosition) {
          if (!isScrollingDown) {
            setIsScrollingDown(true)
          }
        } else if (isScrollingDown) {
          setIsScrollingDown(false)
        }
      } else {
        setIsScrollingDown(false)
      }

      setLastScrollPosition(scrollPosition)
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => document.removeEventListener("scroll", handleScroll)
  })

  useEffect(() => {
    if (active) {
      document.querySelector("html").classList.add("no-scroll")
      document.querySelector("body").classList.add("no-scroll")
    } else {
      document.querySelector("html").classList.remove("no-scroll")
      document.querySelector("body").classList.remove("no-scroll")
    }
  }, [active])

  const navigationLinks = [
    {
      label: "Opiniones",
      url: "/#opiniones",
    },
    {
      label: "¿Qué es?",
      url: "/#que-es",
    },
    {
      label: "¿Cómo se hace?",
      url: "/#como-se-hace",
    },
    {
      label: "Tipos de tests",
      url: "/#tipos-de-tests",
    },
    {
      label: "Sobre nosotros",
      url: "/#sobre-nosotros",
    },
  ]

  const animationVariants = {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: -32,
      opacity: 0,
    },
  }

  return (
    <StyledMenu active={active} isScrollingDown={isScrollingDown}>
      <Container>
        <div className="d-flex align-items-center">
          <Link to="/" className="d-flex" title="Logo">
            <IconLogo />
          </Link>

          <div className="menu__countries d-flex">
            <CountriesDropdown />
          </div>
        </div>

        <button
          type="button"
          className="menu__toggler d-lg-none bg--magenta"
          onClick={() => setActive(!active)}
        >
          <span className="bg--blanco" />
          <span className="bg--blanco" />
          <span className="bg--blanco" />
        </button>

        <div className="menu__content bg--blanco">
          <ul className="d-lg-flex">
            {navigationLinks.map((link, index) => (
              <motion.li
                key={link.label}
                initial="closed"
                animate="open"
                variants={animationVariants}
                transition={{
                  delay: index * 0.1,
                }}
              >
                <Link
                  to={link.url}
                  className="color--azul button-small color-hover--magenta font-weight--700"
                  onClick={() => setActive(false)}
                >
                  {link.label}
                </Link>
              </motion.li>
            ))}
          </ul>
        </div>
      </Container>
    </StyledMenu>
  )
}

export default Menu
